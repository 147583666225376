// assets
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import TemplateIcon from '@mui/icons-material/Subscriptions'
import MyProjectsIcon from '@mui/icons-material/LocalMovies'
import PubcliAssetIcon from '@mui/icons-material/VideoCameraFront'
import EventIcon from '@mui/icons-material/Event'
import PeopleIcon from '@mui/icons-material/People'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import CopyrightContentUpIcon from '@mui/icons-material/Person3'
import MyAssetIcon from '@mui/icons-material/VideoLibrary'
import DashboardIcon from '@mui/icons-material/Dashboard'
import CategoryIcon from '@mui/icons-material/Category'
import OntololgyIcon from '@mui/icons-material/Category'
import IdentifierIcon from '@mui/icons-material/CardMembership'
import MyAssetList from '../../../features/myasset/myasset-list'

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'pages',
  // title: 'Video Platform',
  // // caption: "Upload assets",
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: 'upcomingevents',
      title: 'Upcoming Events',
      type: 'item',
      url: '/upcoming/events',
      icon: EventIcon,
      breadcrumbs: false,
    },

    {
      id: 'trendingtopics',
      title: 'Trending Topics',
      type: 'item',
      url: '/trending/topics',
      icon: TrendingUpIcon,
      breadcrumbs: false,
    },

    {
      id: 'copyright content',
      title: ' Copyright Content',
      type: 'item',
      url: '/copyright-content/list',
      icon: CopyrightContentUpIcon,
      breadcrumbs: false,
    },

    {
      id: 'publicAssets',
      title: 'Public Assets',
      type: 'collapse',
      icon: PubcliAssetIcon,
      breadcrumbs: false,

      children: [
        {
          id: 'publicVideo',
          title: 'Video',
          type: 'item',
          url: '/assets/list',
          target: false,
        },
      ],
    },

    {
      id: 'myAssets',
      title: 'My Assets',
      type: 'collapse',
      icon: MyAssetIcon,
      breadcrumbs: false,
      children: [
        {
          id: 'privateVideo',
          title: 'Video',
          type: 'item',
          url: '/myassets/list',
          target: false,
        },
        {
          id: 'privateImage',
          title: 'Image',
          type: 'item',
          url: '/image/list',
          target: false,
        },
        {
          id: 'privateFonts',
          title: 'Fonts',
          type: 'item',
          url: '/font/list',
          target: false,
        },
      ],
    },
    {
      id: 'templates',
      title: 'My Templates',
      type: 'item',
      url: '/templates/list',
      icon: TemplateIcon,
      breadcrumbs: false,
    },
    {
      id: 'identifier',
      title: 'My Identifiers',
      type: 'item',
      url: '/identifier/list',
      icon: IdentifierIcon,
      breadcrumbs: false,
    },
    {
      id: 'tags',
      title: 'Ontology',
      type: 'item',
      url: '/entity-tags/list',
      icon: OntololgyIcon,
      breadcrumbs: false,
    },

    {
      id: 'tags',
      title: 'Composition Tags',
      type: 'item',
      url: '/composition-tags/list',
      icon: OntololgyIcon,
      breadcrumbs: false,
    },

    {
      id: 'vendor',
      title: 'Vendor',
      type: 'item',
      url: '/vendor/list',
      icon: PeopleIcon,
      breadcrumbs: false,
    },
    // {
    //   id: 'categories',
    //   title: 'Categories',
    //   type: 'item',
    //   url: '/category/list',
    //   icon: CategoryIcon,
    //   breadcrumbs: false,
    // },
    // {
    //   id: 'sub-category',
    //   title: 'SubCategories',
    //   type: 'item',
    //   url: '/subcategory/list',
    //   icon: CategoryIcon,
    //   breadcrumbs: false,
    // },
  ],
}

export default pages
