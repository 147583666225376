import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useTable } from 'react-table'

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    //   backgroundColor: theme.palette.common.black,
    //   color: theme.palette.common.white,
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

function BasicTable({ data = [], columns = [], columnsToHide = [] }) {
  const cols = React.useMemo(() => columns, [columns])
  const colsToHide = React.useMemo(() => columnsToHide, [columnsToHide])
  const tableData = React.useMemo(() => data || [], [data])

  // console.log('cols', cols, tableData, colsToHide)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns: cols,
    data: tableData,
    initialState: {
      hiddenColumns: colsToHide,
    },
  })

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 500 }}
        aria-label="simple table"
        {...getTableProps()}
      >
        <TableHead>
          {headerGroups.map(headerGroup => (
            <StyledTableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <StyledTableCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <StyledTableRow {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <StyledTableCell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </StyledTableCell>
                  )
                })}
              </StyledTableRow>
            )
          })}
          {rows.length === 0 && (
            <StyledTableRow>
              <StyledTableCell
                colSpan={columns.length}
                sx={{ textAlign: 'center' }}
              >
                <Typography variant="body2">No data found </Typography>
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BasicTable
