import * as requestFromServer from './assetCrud'
import assetSlice from './assetSlice'
const { actions } = assetSlice

/* =========Get Asset Categories ========= */
export const AssetCategoryAction = () => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .GetAssetCategories()
      .then(response => {
        dispatch(actions.getAssetCategories(response.data))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No Categories found'
        reject(error)
      })
  })
}

/* =========Get Asset SubCategories ========= */
export const AssetSubCategoryAction = id => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .GetAssetSubCategories(id)
      .then(response => {
        dispatch(actions.getAssetSubCategories(response.data))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No SubCategories found'
        reject(error)
      })
  })
}

/* =========Send Content Tags to server ========= */
export const MetadataAction = data => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .PostMetadata(data)
      .then(response => {
        dispatch(actions.sendContentTags({ response }))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No Tags found'
        reject(error)
      })
  })
}

/* =========Send Content Tags to server ========= */
export const AssetsIdAction = (subcat, data) => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .PostMetadata(subcat, data)
      .then(response => {
        dispatch(actions.sendContentTags({ response }))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No SubCategories found'
        reject(error)
      })
  })
}

/* =========Get Assets List ========= */
export const GetAssetListAction = data => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .GetAssetList(data)
      .then(response => {
        dispatch(actions.getAsset({ response }))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No Assets found'
        reject(error)
      })
  })
}

/* =========Get All Assets with pagination ========= */
export const GetAllAssetListAction = pagination => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .GetAllAssetList(pagination)
      .then(response => {
        dispatch(actions.getAllAsset({ response }))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No Assets found'
        reject(error)
      })
  })
}

/* =========Get Assets List By ID========= */
export const GetAssetListByIdAction = id => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .GetAssetListById(id)
      .then(response => {
        dispatch(actions.GetAssetData({ response }))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No Assets found'
        reject(error)
      })
  })
}

/* =========Edit DraftAssets ========= */
export const UpdateAssetDraftAction = (id, formData) => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .EditAssetDraft(id, formData)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========Create Segment========= */
export const CreateSegmentAction = data => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .CreateSegment(data)
      .then(response => {
        dispatch(actions.createSegment({ response }))
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========Delete Assets List ========= */
export const AssetListDeleteAction = id => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .DeleteAsset(id)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========EditSegment========= */
export const EditSegmentAction = (data, id) => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .EditSegment(data, id)
      .then(response => {
        // dispatch(actions.createSegment({ response }))
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========Delete Segment========= */
export const DeleteSegmentAction = id => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .DeleteSegment(id)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========Publish Asset========= */
export const PublishAssetAction = id => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .PublishAsset(id)
      .then(response => {
        // dispatch(actions.createSegment({ response }))
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========InReviewAsset========= */
export const InReviewAssetAction = id => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .InReviewAsset(id)
      .then(response => {
        // dispatch(actions.createSegment({ response }))
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========Image Upload========= */
export const ImageUploadAction = data => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .ImageUpload(data)
      .then(response => {
        // dispatch(actions.createSegment({ response }))
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========Get Images========= */
export const GetAllImagesAction = pagination => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .GetAllImages(pagination)
      .then(response => {
        dispatch(actions.getImages(response.data))
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========Get Single Image By ID========= */
export const GetSingleImageAction = id => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .GetSingleImage(id)
      .then(response => {
        dispatch(actions.GetSingleImage(response.data))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No Assets found'
        reject(error)
      })
  })
}

/* =========Put Images========= */
export const UpdateImageAction = (id, data) => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .UpdateImage(id, data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========Delete Images========= */
export const DeleteImageAction = id => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .DeleteImage(id)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========Upload Font========= */
export const FontUploadAction = data => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .FontUpload(data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========Get Fonts with pagination========= */
export const GetAllFontAction = pagination => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .GetAllFont(pagination)
      .then(response => {
        dispatch(actions.getFont(response.data))
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========Get Fonts without  pagination========= */
export const GetFontsAction = () => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .GetFonts()
      .then(response => {
        dispatch(actions.getFonts(response.data))
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========Delete Fonts========= */
export const DeleteFontAction = id => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .DeleteFont(id)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========Delete Video File ========= */
export const DeleteVideoAction = fileName => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .VideoFileDelete(fileName)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========Delete Slog File ========= */
export const DeleteSlogAction = fileName => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .SlogFileDelete(fileName)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}
