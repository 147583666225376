import {
  Button,
  CircularProgress,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'

export function FormikStep({ children }) {
  return <>{children}</>
}

export function FormikStepper({ children, ...props }) {
  const childrenArray = React.Children.toArray(children)
  const [step, setStep] = useState(0)
  const currentChild = childrenArray[step]
  // eslint-disable-next-line no-unused-vars
  const [completed, setCompleted] = useState(false)

  // const totalSteps = childrenArray.length
  function isLastStep() {
    return step === childrenArray.length - 1
  }

  return (
    <Formik
      initialValues={currentChild.props.initialValues}
      validate={currentChild.props.validate}
      onSubmit={async (values, helpers) => {
        if (currentChild.props.onSubmit) {
          try {
            await currentChild.props.onSubmit(values, helpers)
          } catch (err) {
            return err
          }
        }
        if (isLastStep()) {
          await props.onSubmit(values, helpers)
          setCompleted(true)
        } else {
          setStep(s => s + 1)
          // setStep(Math.min(step + 1, totalSteps - 1));
          helpers.setTouched({})
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          {props.heading ? (
            <>
              <div
                style={{
                  display: 'block',
                  width: '100%',
                  height: '70px',
                }}
              >
                <Typography variant="h3" my={2}>
                  {props.heading}
                </Typography>
                <Stepper
                  // sx={{ width: '30%', float: 'right' }}
                  alternativeLabel
                  activeStep={step}
                >
                  {childrenArray.map((child, index) => (
                    <Step
                      key={child.props.label}
                      completed={step > index || child.props.completed}
                      sx={{ width: '200px' }}
                    >
                      <StepLabel
                        sx={{
                          backgroundColor: '#ffffff',
                          border: 'solid 5px #ffffff',
                        }}
                      >
                        {child.props.label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </>
          ) : (
            <Stepper alternativeLabel activeStep={step}>
              {childrenArray.map((child, index) => (
                <Step
                  key={child.props.label}
                  completed={step > index || child.props.completed}
                >
                  <StepLabel>{child.props.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          )}

          {currentChild}

          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            // sx={{
            //   display: 'grid',
            //   justifyContent: 'end',
            //   wrap: 'nowrap',
            //   direction: 'row',
            // }}
          >
            {step > 0 ? (
              <Grid item>
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  onClick={() => setStep(s => s - 1)}
                >
                  Back
                </Button>
              </Grid>
            ) : (
              <Grid item></Grid>
            )}
            <Grid item>
              <Button
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
              >
                {isSubmitting ? 'Submitting' : isLastStep() ? 'Done' : 'Next'}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
