// assets
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import TemplateIcon from '@mui/icons-material/Subscriptions'
import MyProjectsIcon from '@mui/icons-material/LocalMovies'
import PubcliAssetIcon from '@mui/icons-material/VideoCameraFront'
import PeopleIcon from '@mui/icons-material/People'
import MyAssetIcon from '@mui/icons-material/VideoLibrary'
import DashboardIcon from '@mui/icons-material/Dashboard'
import CategoryIcon from '@mui/icons-material/Category'

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'pages',
  // title: 'Video Platform',
  // // caption: "Upload assets",
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: 'myassetslist',
      title: 'My Assets',
      type: 'item',
      url: '/myassets/list',
      icon: MyAssetIcon,
      breadcrumbs: false,
    },

    {
      id: 'templates',
      title: ' My Templates',
      type: 'item',
      url: '/templates/list',
      icon: TemplateIcon,
      breadcrumbs: false,
    },
  ],
}

export default pages
