export const defaultPage = 0
export const defaultSize = 5
export const defaultSortBy = 'createdDate'
export const defaultSortDir = 'desc'
export const defaultTotalPages = 1
export const defaultTotalElements = 0
export const defaultSearch = ''
export const ROLES = {
  USER: 'User',
  ADMIN: 'Admin',
}
