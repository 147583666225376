import { useRef, useState } from 'react'
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ViewListIcon from '@mui/icons-material/ViewList'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
// component

// ----------------------------------------------------------------------

export default function ActionFormatter(row, formatExtraData) {
  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertIcon width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {/* TODO: Below conditions need to move out, as this file is a generic component */}
        {row?.lifeCycleStage != 'Draft' &&
        (row?.mediaInfo == null ||
          row?.thumbnail == null ||
          row?.lowResolution == null ||
          row?.colorGrading == null) ? (
          // row?.lifeCycleStage === 'ReadyForReview' ||
          // row?.lifeCycleStage === 'InReview' ||
          // row?.lifeCycleStage === 'Published'
          <MenuItem
            sx={{ color: 'text.secondary' }}
            onClick={() => {
              formatExtraData.onView(row)
              setIsOpen(false)
            }}
          >
            <ListItemIcon>
              <ViewListIcon width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="View"
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        ) : (
          ''
        )}
        {/* TODO: Below conditions need to move out, as this file is a generic component */}
        {row?.lifeCycleStage === 'Draft' ||
        (row?.mediaInfo &&
          row?.thumbnail &&
          row?.lowResolution &&
          row?.colorGrading) ||
        row?.lifeCycleStage === 'InReview' ||
        row?.lifeCycleStage === 'Published' ? (
          <MenuItem
            sx={{ color: 'text.secondary' }}
            onClick={() => formatExtraData.onEdit(row)}
          >
            <ListItemIcon>
              <EditIcon width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        ) : (
          ''
        )}

        {(row?.mediaInfo &&
          row?.thumbnail &&
          row?.lowResolution &&
          row?.colorGrading) ||
        row?.lifeCycleStage === 'InReview' ||
        row?.lifeCycleStage === 'Published' ? (
          <MenuItem
            sx={{ color: 'text.secondary' }}
            onClick={() => {
              formatExtraData.onDownload(row)
              setIsOpen(false)
            }}
          >
            <ListItemIcon>
              <FileDownloadIcon width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Download"
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        ) : (
          ''
        )}
        {/* TODO: Below conditions need to move out, as this file is a generic component */}
        {row?.lifeCycleStage === 'Draft' ||
        (row?.mediaInfo &&
          row?.thumbnail &&
          row?.lowResolution &&
          row?.colorGrading) ||
        row?.lifeCycleStage === 'InReview' ||
        row?.lifeCycleStage === 'Analysing' ||
        row?.lifeCycleStage === 'Published' ? (
          <MenuItem
            sx={{ color: 'text.secondary' }}
            onClick={() => {
              formatExtraData.onDelete(row)
              setIsOpen(false)
            }}
          >
            <ListItemIcon>
              <DeleteIcon width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        ) : (
          ''
        )}
      </Menu>
    </>
  )
}
