import React, { useEffect, useState } from 'react'

import { FormControl, useTheme } from '@mui/material'
import { ErrorMessage, Field } from 'formik'
import TextError from './TextError'
import Select from 'react-select'

/**
 * function to render creatable from react-select
 * @param {*} creatable creatable props for multiselect tag
 * @returns customized react-select
 */

const ReactSelect = ({
  onChange = () => {},
  options = [],
  isMulti,
  disabled = false,
  isEdit,
  ...props
}) => {
  const theme = useTheme()
  const {
    label,
    name,
    formik: { field, form, meta },
    // eslint-disable-next-line no-unused-vars
    ...rest
  } = props
  const [colorError, setColorError] = useState(false)

  useEffect(() => {
    setColorError(meta.error && meta.touched)
  }, [meta.error, meta.touched])

  const customStyles = {
    control: (baseStyles, state) =>
      colorError
        ? {
            ...baseStyles,
            baseStyles: `0 0 0 1px ${theme.palette.error.main} !important`,
            borderColor: `${theme.palette.error.main} !important`,
            color: `${theme.palette.error.main} !important`,
          }
        : {
            ...baseStyles,
            //   borderColor: state.isFocused ? 'grey' : `${theme.palette.error.main}`,
          },
    container: styles => ({
      padding: 0,
    }),
    placeholder: defaultStyles =>
      colorError
        ? {
            ...defaultStyles,
            color: `${theme.palette.error.main} !important`,
          }
        : {
            ...defaultStyles,
            color: `${theme.palette.grey[500]}`,
            fontWeight: theme.typography.fontWeightMedium,
          },
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        fontWeight: theme.typography.fontWeightMedium,
      }
    },
    singleValueLabel: (styles, { data }) => ({
      ...styles,
      color: theme.palette.common.white,
      fontSize: theme.typography.fontSize,
      padding: '2px 4px',
      fontWeight: theme.typography.fontWeightMedium,
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        borderRadius: '20px',
        padding: '2px',
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
      }
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: theme.palette.common.white,
      fontSize: theme.typography.fontSize,
      padding: '2px 4px',
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      fontSize: theme.typography.body1.fontSize,
      ':hover': {
        //   backgroundColor: data.color,
        color: 'white',
        cursor: 'pointer',
      },
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
  }

  const updateTags = (tags, form, field) => {
    // if (!tags) {
    //   onChange([])
    //   return
    // }
    const updatedTags = isMulti ? tags.map(tag => tag.value) : tags?.value || ''
    form.setFieldValue(field.name, updatedTags)
    onChange(updatedTags)
  }

  return (
    <FormControl fullWidth error={meta.error && meta.touched}>
      {/* <Select
        {...field}
        {...rest}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        styles={customStyles}
        // components={{
        //   DropdownIndicator: () => null,
        //   IndicatorSeparator: () => null,
        // }}
        isSearchable
        isClearable
        isMulti={isMulti}
        isDisabled={disabled}
        placeholder={props.label || 'Enter Values'}
        options={options}
        noOptionsMessage={() => props.noOptionMessage || 'No Data Available'}
        onBlur={field.setFieldTouched}
        value={
          isMulti
            ? field.value?.map((tag, index) => ({
                value: tag,
                label: tag,
              }))
            : {
                value: field.value,
                label: field.value,
              }
        }
        defaultValue={
          isMulti
            ? field.value?.map((tag, index) => ({
                value: tag,
                label: tag,
              }))
            : {
                value: field.value,
                label: field.value,
              }
        }
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
        onChange={option => {
          updateTags(option, form, field)
        }}
      /> */}
      <Select
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        styles={customStyles}
        isSearchable={true}
        isClearable={true}
        isMulti={isMulti}
        isDisabled={disabled}
        options={options}
        defaultValue={
          isMulti
            ? field.value?.map((tag, index) => ({
                value: tag,
                label: tag,
              }))
            : field?.value
            ? {
                value: field?.value || '',
                label: field?.value || '',
              }
            : ''
        }
        // value={
        //   isMulti
        //     ? field.value?.map((tag, index) => ({
        //         value: tag,
        //         label: tag,
        //       }))
        //     : {
        //         value: field?.value || '',
        //         label: field?.value || '',
        //       }
        // }
        placeholder={props.label || 'Enter Values'}
        isLoading={false}
        loadingMessage={() => 'Fetching data'}
        noOptionsMessage={() => props.noOptionMessage || 'No Data Available'}
        onBlur={field.setFieldTouched}
        onChange={option => {
          updateTags(option, form, field)
        }}
      />
      <ErrorMessage component={TextError} name={field.name} />
    </FormControl>
  )
}

const AutocompleteField = ({ ...props }) => {
  return (
    <>
      <Field name={props.name}>
        {formik => <ReactSelect formik={formik} {...props} />}
      </Field>
    </>
  )
}

export default AutocompleteField
