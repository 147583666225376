import __env from '../../../env'
import instance from '../../../middleware/axiosInstance'

export const GetAssetCategories = () => {
  return instance.get(
    `${process.env.REACT_APP_BACKEND_URL}/master-data/category/list`
  )
}

export const GetAssetSubCategories = id => {
  return instance.get(
    `${process.env.REACT_APP_BACKEND_URL}/master-data/category/subCategoryList/${id}`
  )
}

export const PostMetadata = data => {
  return instance.post(
    `${process.env.REACT_APP_BACKEND_URL}/whilter-owned/video-clip`,
    data
  )
}

export const GetAssetList = () => {
  // const { headers } = getToken()
  return instance.get(
    `${process.env.REACT_APP_BACKEND_URL}/whilter-owned/video-clip/list`
  )
}

export const GetAllAssetList = pageable => {
  // const { headers } = getToken()
  return instance.get(
    `${process.env.REACT_APP_BACKEND_URL}/whilter-owned/video-clip/list/paging?pageNo=${pageable.pageNo}&pageSize=${pageable.pageSize}&sortBy=${pageable.sortBy}&sortDir=${pageable.sortDir}&search=${pageable.search}`
  )
}

export const GetAssetListById = id => {
  return instance.get(
    `${process.env.REACT_APP_BACKEND_URL}/whilter-owned/video-clip/${id}`
  )
}

export const EditAssetDraft = (id, formData) => {
  return instance.put(
    `${process.env.REACT_APP_BACKEND_URL}/whilter-owned/video-clip?id=${id}`,
    formData
  )
}

export const CreateSegment = data => {
  return instance.post(
    `${process.env.REACT_APP_BACKEND_URL}/whilter-owned/segments`,
    data
  )
}

export const InReviewAsset = id => {
  // const { headers } = getToken()

  return instance.put(
    `${process.env.REACT_APP_BACKEND_URL}/whilter-owned/video-clip/status?id=${id}&status=InReview`
  )
}

export const PublishAsset = id => {
  // const { headers } = getToken()

  return instance.put(
    `${process.env.REACT_APP_BACKEND_URL}/whilter-owned/video-clip/status?id=${id}&status=Published`
  )
}

export const EditSegment = (data, id) => {
  return instance.put(
    `${process.env.REACT_APP_BACKEND_URL}/whilter-owned/segments?segmentId=${id}`,
    data
  )
}

export const DeleteSegment = id => {
  return instance.delete(
    `${process.env.REACT_APP_BACKEND_URL}/whilter-owned/segments?segmentId=${id}`
  )
}

export const DeleteAsset = id => {
  return instance.delete(
    `${process.env.REACT_APP_BACKEND_URL}/whilter-owned/video-clip?id=${id}`
  )
}

//image

export const ImageUpload = data => {
  return instance.post(
    `${process.env.REACT_APP_BACKEND_URL}/image-library/images/`,
    data
  )
}

export const GetAllImages = pageable => {
  return instance.get(
    `${process.env.REACT_APP_BACKEND_URL}/image-library/images/list/paging?pageNo=${pageable.pageNo}&pageSize=${pageable.pageSize}&sortBy=${pageable.sortBy}&sortDir=${pageable.sortDir}&search=${pageable.search}`
  )
}

export const GetSingleImage = id => {
  return instance.get(
    `${process.env.REACT_APP_BACKEND_URL}/image-library/images/${id}`
  )
}

export const UpdateImage = (id, data) => {
  return instance.put(
    `${process.env.REACT_APP_BACKEND_URL}/image-library/images/add-tags/${id}`,
    data
  )
}

export const DeleteImage = id => {
  return instance.delete(
    `${process.env.REACT_APP_BACKEND_URL}/image-library/images/${id}`
  )
}

//fonts
export const FontUpload = data => {
  return instance.post(
    `${process.env.REACT_APP_BACKEND_URL}/font-library/fonts/`,
    data
  )
}

export const GetAllFont = pageable => {
  return instance.get(
    `${process.env.REACT_APP_BACKEND_URL}/font-library/fonts/list/paging?pageNo=${pageable.pageNo}&pageSize=${pageable.pageSize}&sortBy=${pageable.sortBy}&sortDir=${pageable.sortDir}&search=${pageable.search}`
  )
}

export const GetFonts = () => {
  return instance.get(
    `${process.env.REACT_APP_BACKEND_URL}/font-library/fonts/list`
  )
}
export const DeleteFont = id => {
  return instance.delete(
    `${process.env.REACT_APP_BACKEND_URL}/font-library/fonts/${id}`
  )
}

export const VideoFileDelete = fileName => {
  return instance.put(`${__env.dataApi}/media/videoAsset/${fileName}`)
}

export const SlogFileDelete = fileName => {
  return instance.put(`${__env.dataApi}/media/videoAsset/slog/${fileName}`)
}
