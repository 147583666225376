import { useEffect, useRef, useState } from 'react'
// material
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from '@mui/material'
// components
import PropTypes from 'prop-types'

import SearchNotFound from '../../components/SearchNotFound'

// mock
import Scrollbar from '../../components/Scrollbar'
import MuiTableHeader from './MuiTableHeader'
import MuiTableToolbar from './MuiTableToolbar'
import MuiTableBody from './MuiTableBody'
import useTableHelper from './useTableHelper'

const SORT_ASC = 'asc'
const SORT_DESC = 'desc'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

MuiTable.propTypes = {
  records: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  showCheckbox: PropTypes.bool,
  showToolbar: PropTypes.bool,
  remote: PropTypes.bool,
  searchQuery: PropTypes.string,
  keyField: PropTypes.string.isRequired,
  paginationProps: PropTypes.shape({
    totalCount: PropTypes.number,
    rowsPerPage: PropTypes.number,
    page: PropTypes.number,
    orderBy: PropTypes.string,
    order: PropTypes.oneOf([SORT_DESC, SORT_ASC]),
  }),
  onTableChange: PropTypes.func,
  onRowsSelect: PropTypes.func,
}

export default function MuiTable({
  records = [],
  columns = [],
  showCheckbox = false,
  showToolbar = false,
  remote = false,
  keyField = 'id',
  searchQuery = '',
  paginationProps = {
    totalCount: 0,
    rowsPerPage: 5,
    page: 0,
    orderBy: 'id',
    order: 'asc',
  },
  onTableChange = () => {},
  onRowsSelect = () => {},
}) {
  const {
    applySortFilter,
    handleRequestSort,
    getComparator,
    handleSearch,
    handleChangeRowsPerPage,
    handleChangePage,
    eventType,
    search,
    pagination: { rowsPerPage, page, order, orderBy },
  } = useTableHelper(paginationProps, searchQuery)

  const [selected, setSelected] = useState([])
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current)
      onTableChange(eventType, {
        rowsPerPage,
        page,
        order,
        orderBy,
        search,
      })
    else didMount.current = true

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order, orderBy, rowsPerPage, search, eventType])

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = records.map(n => n[keyField])
      setSelected(newSelecteds)
      onRowsSelect(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleSelectClick = newSelecteds => {
    setSelected(newSelecteds)
    onRowsSelect(newSelecteds)
  }

  const filteredList = remote
    ? records
    : applySortFilter(records, getComparator(order, orderBy), search).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )

  const isNotFound = filteredList.length === 0

  return (
    <Card>
      {showToolbar && (
        <MuiTableToolbar
          numSelected={selected.length}
          searchTxt={search}
          handleSearch={handleSearch}
        />
      )}

      {/* <Scrollbar sx={{ minHeight: '200' }}>*/}
      <TableContainer sx={{ minWidth: 200 }}>
        <Table>
          <MuiTableHeader
            order={order}
            orderBy={orderBy}
            columns={columns}
            rowCount={records.length}
            numSelected={selected.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
            showCheckbox={showCheckbox}
          />

          <MuiTableBody
            selected={selected}
            setSelected={handleSelectClick}
            page={page}
            rowsPerPage={rowsPerPage}
            filteredList={filteredList || []}
            records={records}
            showCheckbox={showCheckbox}
            columns={columns}
            keyField={keyField}
          />

          {isNotFound && (
            <TableBody>
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={columns.length}
                  sx={{ py: 3 }}
                >
                  <SearchNotFound searchQuery={search} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {/*</Scrollbar>*/}

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={remote ? paginationProps.totalCount : filteredList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  )
}
