import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import { FormControl, FormControlLabel, Switch } from '@mui/material'

function SwitchField(props) {
  const { label, name, ...rest } = props
  return (
    <>
      <Field name={name}>
        {({ field, form, meta }) => (
          <FormControl error={meta.error && meta.touched}>
            <FormControlLabel
              {...field}
              {...rest}
              checked={field.value}
              control={<Switch />}
              label={label}
            />
            <ErrorMessage component={TextError} name={name} />
          </FormControl>
        )}
      </Field>
    </>
  )
}

export default SwitchField
