import { useEffect, useState } from 'react'

// material-ui
import { Grid } from '@mui/material'

// project imports
import { FilledInput, FormControl, InputAdornment, Stack } from '@mui/material'

import { SearchOutlined } from '@ant-design/icons'

// progress loader for waiting
//import ProgressLoader from "../../ui-components/ProgressLoader";
//import Loader from "../../components/Loader";

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Home = () => {
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(false)
  }, [])

  return (
    <>
      <Grid
        style={{
          textAlign: 'center',
          alignItems: 'center',
          margin: 'auto',
          width: '100%',
        }}
      >
        <Stack>
          <h1
            style={{
              fontFamily: 'revert',
              lineHeight: '32px',
              marginTop: '50px',
              marginBottom: '50px',
            }}
          >
            Over 10000+ video clips
            <br />
            curated for your needs
          </h1>
        </Stack>
        <FormControl
          sx={{
            width: '50%',
            marginRight: '10px',
          }}
        >
          <FilledInput
            size="small"
            id="header-search"
            sx={{
              fontSize: '28px',
              '& input::placeholder': {
                fontSize: '28px',
              },
              paddingBottom: '10px',
              paddingLeft: '20px',
            }}
            startAdornment={
              <InputAdornment position="start" sx={{ marginTop: '10px' }}>
                <SearchOutlined />
              </InputAdornment>
            }
            aria-describedby="header-search-text"
            inputProps={{
              'aria-label': 'weight',
            }}
            placeholder="Search..."
          />
        </FormControl>
        <Grid item xs={12} mt={30} sx={{ display: 'block' }}>
          <Grid
            item
            xs={3}
            sx={{
              display: 'inline',
              width: '200px',
              padding: '100px',
              margin: '50px',
              backgroundColor: '#3498db',
              opacity: '0.5',
            }}
          >
            <span style={{ color: '#222222', fontSize: '24px' }}>Clip 1</span>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: 'inline',
              width: '200px',
              padding: '100px',
              margin: '50px',
              backgroundColor: '#3498db',
              opacity: '0.4',
            }}
          >
            <span style={{ color: '#222222', fontSize: '24px' }}>Clip 2</span>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: 'inline',
              width: '200px',
              padding: '100px',
              margin: '50px',
              backgroundColor: '#3498db',
              opacity: '0.3',
            }}
          >
            <span style={{ color: '#222222', fontSize: '24px' }}>Clip 3</span>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: 'inline',
              width: '200px',
              padding: '100px',
              margin: '50px',
              backgroundColor: '#3498db',
              opacity: '0.2',
            }}
          >
            <span style={{ color: '#222222', fontSize: '24px' }}>Clip 4</span>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Home
