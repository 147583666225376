import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

function SelectField(props) {
  const { label, name, options, ...rest } = props

  return (
    <Field name={name}>
      {({ field, form, meta }) => (
        <FormControl fullWidth error={meta.error && meta.touched}>
          <InputLabel>{label}</InputLabel>
          <Select {...field} {...rest} label={label}>
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.key}
              </MenuItem>
            ))}
          </Select>
          <ErrorMessage component={TextError} name={name} />
        </FormControl>
      )}
    </Field>
  )
}

export default SelectField
