import React from 'react'
import { Field } from 'formik'
import { FormControl, TextField } from '@mui/material'

function Input(props) {
  const { label, name, ...rest } = props
  return (
    <Field name={name}>
      {({ field, form, meta }) => (
        <FormControl fullWidth error={meta.error && meta.touched}>
          <TextField
            fullWidth
            {...field}
            {...rest}
            label={label}
            error={form.touched[name] && Boolean(form.errors[name])}
            helperText={form.touched[name] && form.errors[name]}
          />
        </FormControl>
      )}
    </Field>
  )
}

export default Input
