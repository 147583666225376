import { useRef, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
// material
import { alpha } from '@mui/material/styles'
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
  Icon,
  ListItemIcon,
  ListItemText,
  MenuList,
} from '@mui/material'
// components
import MenuPopover from '../../../../components/MenuPopover'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import avatarImg from './../../../../assets/images/users/user-round.svg'
import { connect } from 'react-redux'
import { logoutUser } from './../../../../features/auth/redux/authAction'
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: <HomeOutlinedIcon />,
    linkTo: '/',
  },
  {
    label: 'Settings',
    icon: <SettingsOutlinedIcon />,
    linkTo: '/',
  },
  {
    label: 'Profile',
    icon: <ManageAccountsOutlinedIcon />,
    linkTo: '#',
  },
]

// ----------------------------------------------------------------------

function ProfileSection({ userInfo, ...props }) {
  const navigate = useNavigate()
  const image = userInfo?.preferred_username.charAt(0).toUpperCase()
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = async () => {
    localStorage.removeItem('cognito_access_token')
    localStorage.removeItem('cognito_id_token')
    localStorage.removeItem('cognito_refresh_token')
    localStorage.removeItem('enum_data')
    // await  props.logoutUser()
    window.location.href = `${process.env.REACT_APP_AWS_COGNITO}/logout?response_type=code&client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENTID}&redirect_uri=${process.env.REACT_APP_AWS_COGNITO_REDIRECT_URI}`
    navigate('/')
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: theme => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <Avatar alt="photoURL">{image} </Avatar>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            Welcome,{userInfo?.preferred_username}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userInfo?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />
        <MenuList>
          {MENU_OPTIONS.map(option => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              {/* <Icon
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            >{option.icon}</Icon> */}
              <ListItemIcon>{option.icon}</ListItemIcon>

              <ListItemText>{option.label}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  )
}

// export default ProfileSection;
const mapStateToProps = state => ({
  userlogin: state.auth.googleUser,
  facebooklogin: state.auth.facebookUser,
  user: state.auth.userinfo,
  loginPage: state.auth.loginPage,
  pkceStateValue: state.auth.pkcestatevalue,
  pkceCodeVerifier: state.auth.pkcecodeverifiervalue,
  pkceuserprofile: state.auth.pkceuserprofile,
  accesstoken: state.auth.pkceaccesstoken,
})
const mapActionsToProps = {
  logoutUser,
}

export default connect(mapStateToProps, mapActionsToProps)(ProfileSection)
