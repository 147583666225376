// material-ui
import { Avatar, Box, IconButton, Typography } from '@mui/material'
// import { useTheme } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom'

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const navigate = useNavigate()

  return (
    <IconButton onClick={() => navigate('/')} disableRipple sx={{ p: 0 }}>
      {/* <Avatar sx={{ bgcolor: "primary.main", width: 30, height: 30 }}>
        <Typography variant="h4" align="center" color="white">
          JB
        </Typography>
      </Avatar>
      <Typography variant="h3" sx={{ color: "primary.main", marginLeft: 1 }}>
        JIO BRAIN
      </Typography> */}
      <Box
        // component="img"
        sx={{
          margin: 0,
          padding: 0,
          backgroundImage: 'url(/assets/WhilterWhiteLogo2.png)',
          backgroundRepeat: 'no-repeat',
          backgroundPositionY: '0px',
          backgroundPositionX: 'center',
          width: '200px',
          height: '48px',
        }}
        alt="whilter"
        // src="/assets/whilter-ai-logo-1.png"
      ></Box>
    </IconButton>
  )
}

export default Logo
