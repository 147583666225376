import React, { useEffect, useState } from 'react'

/* =========Mui components========= */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Paper,
  Typography,
} from '@mui/material'

/* =========react-redux ========= */
import axios from 'axios'
import { connect } from 'react-redux'
import {
  MyAssetListDeleteAction,
  MyAssetListAction,
  AllMyAssetListAction,
} from '../redux/myassetAction'

/* =========react-router-dom ========= */
import { useNavigate } from 'react-router-dom'

/* =========styling ========= */
import AddIcon from '@mui/icons-material/Add'
import { toast } from 'react-toastify'
import { sentenceCase } from 'change-case'
import moment from 'moment'
import Label from '../../../components/Label'
import __env from '../../../env'
import MuiTable from '../../../ui-components/mui-table/MuiTable'
import ActionFormatter from '../myasset-form/components/ActionFormatter'
import instance from '../../../middleware/axiosInstance'
import ImageIcon from '@mui/icons-material/Image'
import { useSearchParams, createSearchParams } from 'react-router-dom'
import {
  defaultPage,
  defaultSize,
  defaultSortBy,
  defaultSortDir,
  defaultTotalElements,
} from '../../../constants'

function MyAssetList({ pageable, ...props }) {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [deleteModal, setDeleteModal] = useState(false)
  const [myAssetList, setMyAssetList] = useState('')
  const [previewModal, setPreviewModal] = useState(false)
  const [previewDetails, setPreviewDetails] = useState(null)
  const [editModal, setEditModal] = useState(false)

  const [pagination, setPagination] = useState({
    pageNo: +searchParams.get('page') || defaultPage,
    totalElements: +searchParams.get('totalElements') || defaultTotalElements,
    pageSize: +searchParams.get('size') || defaultSize,
    sortBy: searchParams.get('sort') || defaultSortBy,
    sortDir: searchParams.get('order') || defaultSortDir,
    search: searchParams.get('search') || '',
  })
  const handlePreview = row => {
    setPreviewDetails(row)
    setPreviewModal(true)
  }
  const columns = [
    {
      id: 'watermark',
      label: 'Video',
      align: 'left',
      alignHeader: 'left',
      formatter: row => {
        if (row?.thumbnail?.url) {
          return (
            <img
              src={row?.thumbnail?.url ? row?.thumbnail?.url : <ImageIcon />}
              height="60"
              width="80"
              style={{ borderRadius: '5px', cursor: 'pointer' }}
              onClick={() => handlePreview(row)}
            />
          )
        } else {
          return (
            <div>
              <span
                style={{
                  display: 'inline-block',
                  width: '80px',
                  height: '60px',
                  backgroundColor: '#efefef',
                  borderRadius: '5px',
                  textAlign: 'center',
                }}
              >
                <ImageIcon
                  sx={{ fontSize: '40px', paddingTop: '18px', opacity: '0.5' }}
                />
              </span>
            </div>
          )
        }
      },
    },

    { id: 'name', label: 'Name', align: 'left', alignHeader: 'left' },

    {
      id: 'lifeCycleStage',
      label: 'Status',
      align: 'left',
      alignHeader: 'left',
      formatter: row => {
        return (
          <Label
            variant="ghost"
            color={
              row?.lifeCycleStage === 'Draft'
                ? 'error'
                : row?.lifeCycleStage === 'Analysing' &&
                  (row?.mediaInfo == null ||
                    row?.thumbnail == null ||
                    row?.lowResolution == null ||
                    row?.colorGrading == null)
                ? 'warning'
                : row?.lifeCycleStage === 'InReview'
                ? 'info'
                : row?.lifeCycleStage === 'Analysing' &&
                  row?.mediaInfo != null &&
                  row?.thumbnail != null &&
                  row?.lowResolution != null &&
                  row?.colorGrading != null
                ? 'info'
                : row?.lifeCycleStage === 'Published'
                ? 'success'
                : 'error'
            }
          >
            {sentenceCase(
              row?.lifeCycleStage === 'Draft' ||
                row?.lifeCycleStage === 'InReview' ||
                row?.lifeCycleStage === 'Published'
                ? row?.lifeCycleStage
                : row?.mediaInfo == null ||
                  row?.thumbnail == null ||
                  row?.colorGrading == null ||
                  row?.lowResolution == null
                ? 'Analysing'
                : 'ReadyForReview'
            )}
          </Label>
        )
      },
    },
    {
      id: 'reelsPosition',
      label: 'Reels Position',
      align: 'left',
      alignHeader: 'left',
      formatter: row =>
        row?.reelsPosition?.length > 0 ? row?.reelsPosition : '--',
    },

    {
      id: 'availability',
      label: 'Availability',
      align: 'left',
      alignHeader: 'left',
      formatter: row => (row?.availability ? 'Yes' : 'No'),
    },

    // {
    //   id: 'createdBy',
    //   label: 'Created By',
    //   align: 'center',
    //   alignHeader: 'center',
    // },

    {
      id: 'createdDate',
      label: 'Added On',
      align: 'left',
      alignHeader: 'left',
      formatter: row => moment(row?.createdDate).format('MMMM D, YYYY'),
    },

    {
      id: 'actions',
      label: 'Actions',
      align: 'center',
      alignHeader: 'center',
      formatter: ActionFormatter,
      formatExtraData: {
        onEdit: item => handleMyAssetsEdit(item),
        onDelete: item => handleDeleteMyAssetModal(item),
        onView: item => handleViewMyAssetModal(item),
        onDownload: item => handleDownloadFile(item),
      },
    },
  ]

  /* =========Get Asset listing ========= */
  useEffect(() => {
    props.AllMyAssetListAction(pagination)
  }, [searchParams])

  /* =========Edit Asset navigation flow ========= */
  const handleMyAssetsEdit = item => {
    if (item.lifeCycleStage === 'Draft') {
      navigate(`/myassets/edit/${item?.id}/draft`, { state: item })
    } else if (item.lifeCycleStage == 'Published') {
      setEditModal(true)
      setMyAssetList(item)
    } else if (
      (item?.mediaInfo &&
        item?.thumbnail &&
        item?.colorGrading &&
        item?.lowResolution) ||
      item.lifeCycleStage === 'InReview'
    ) {
      navigate(`/myassets/edit/${item?.id}`, { state: item })
    } else if (
      (item?.mediaInfo &&
        item?.thumbnail &&
        item?.watermark &&
        item?.colorGrading &&
        item?.contentTags) ||
      item.lifeCycleStage === 'InReview'
    ) {
      navigate(`/myassets/edit/${item?.id}`, { state: item })
    }
  }

  const handleViewMyAssetModal = item => {
    if (item.lifeCycleStage === 'Analysing') {
      navigate(`/myassets/${item?.id}/analysing`, { state: item })
    }
    if (
      (item?.mediaInfo &&
        item?.thumbnail &&
        item?.colorGrading &&
        item?.lowResolution) ||
      item.lifeCycleStage === 'InReview' ||
      item.lifeCycleStage === 'published'
    ) {
      navigate(`/myassets/view/${item?.id}`, { state: item })
    }
  }

  const handlePublishedMyAsset = () => {
    navigate(`/myassets/edit/${myAssetList?.id}`, {
      state: myAssetList,
    })
  }

  /* =========Delete Asset Api implementation  ========= */
  const handleDeleteMyAsset = () => {
    props
      .MyAssetListDeleteAction(myAssetList?.id)
      .then(response => {
        if (response?.status === 200) {
          toast.success('Asset deleted successfully', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          })
          setDeleteModal(false)
          props.AllMyAssetListAction(pagination)
          return Promise.resolve()
        } else {
          toast.error('Something went wrong', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          })
          return Promise.reject()
        }
      })
      .catch(error => {
        toast.error('Something went wrong', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        })
        setDeleteModal(false)
        return Promise.reject()
      })
  }

  /* ========= Dialog  for delete assets ========= */
  const handleDeleteMyAssetModal = item => {
    setDeleteModal(true)
    setMyAssetList(prev => item)
  }

  /* =========Generate Asset Id and navigate to upload page ========= */
  const handleAssets = () => {
    navigate('/myassets/add')
  }

  /* =========Navigate to bulk upload page ========= */
  const handleBulkMyAssets = () => {
    navigate('/myassets/bulk-add')
  }

  /* =========Download Video file Presigned url  ========= */
  const handleDownloadFile = item => {
    const token = localStorage.getItem('pkce_access_token')
    let headers = {
      Authorization: `Bearer ${token}`,
      ContentType: 'application/octet-stream',
    }
    const url = `${process.env.REACT_APP_BACKEND_URL}/my-asset/url?fileName=${item.key}`
    const data = new FormData()
    data.append('fileName', item?.key)

    var config = {
      method: 'get',
      url: url,
      data: data,
    }

    instance(url).then(resp => {
      const videoFileUrl = resp?.data?.signedUrl
      downloadVideoFile(videoFileUrl, item)
    })
  }

  const downloadVideoFile = (videoFileUrl, item) => {
    var config = {
      method: 'get',
      url: videoFileUrl,
      responseType: 'blob',
    }

    axios(config).then(response => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', item?.key)

      // Append to html link element page
      document.body.appendChild(link)
      // Start download
      link.click()
      // Clean up and remove the link
      link.parentNode.removeChild(link)
    })
  }
  const handleTableChange = (type, params) => {
    const queryParams = {
      page: params.page,
      size: params.rowsPerPage,
      sort: params.orderBy,
      order: params.order,
      search: params.search,
    }

    setPagination({
      pageNo: params.page,
      pageSize: params.rowsPerPage,
      sortBy: params.orderBy,
      sortDir: params.order,
      search: params.search,
    })

    navigate({
      pathname: '/myassets/list',
      search: `?${createSearchParams(queryParams)}`,
    })
  }

  return (
    <>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                width: '100%',
                ml: { xs: 0, md: 1 },
                margin: 'auto',
              }}
            >
              <Typography variant="h3">My Asset Library</Typography>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'right',
                justifyContent: 'right',
                ml: { xs: 0, md: 1 },
              }}
            >
              <FormControl
                sx={{
                  width: '50%',
                  marginRight: '10px',
                }}
              ></FormControl>
              <Button
                variant="contained"
                sx={{ mr: 2 }}
                onClick={handleBulkMyAssets}
              >
                <AddIcon /> Bulk Add
              </Button>
              <Button variant="contained" onClick={handleAssets}>
                <AddIcon /> Create
              </Button>
            </Box>
          </div>

          {/* <!--- Delete Model Box ---> */}
          <Dialog
            open={deleteModal}
            onClose={() => setDeleteModal(false)}
            fullWidth
            maxWidth="sm"
          >
            <DialogContent>
              <DialogContentText sx={{ fontWeight: 'bold' }}>
                Do you want to delete this asset? -
                <span style={{ color: 'red' }}>{myAssetList?.name}</span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleDeleteMyAsset()}>Yes</Button>
              <Button onClick={() => setDeleteModal(false)}>No</Button>
            </DialogActions>
          </Dialog>

          {/* <!--- Edit PublishedModel Box ---> */}
          <Dialog
            open={editModal}
            onClose={() => setEditModal(false)}
            maxWidth="sm"
          >
            <DialogTitle>
              <h2>Warning</h2>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Editing a Published asset, will automatically un-publish the
                asset and set its status back to In Review
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => handlePublishedMyAsset()}
              >
                Confirm
              </Button>
              <Button onClick={() => setEditModal(false)}>Cancel</Button>
            </DialogActions>
          </Dialog>

          {/* <!--- Preview Model Box ---> */}
          <Dialog
            open={previewModal}
            onClose={() => setPreviewModal(false)}
            maxWidth="sm"
          >
            <DialogTitle>
              <h2>Preview</h2>
            </DialogTitle>
            <DialogContent>
              <video
                controls
                autoplay="true"
                width="100%"
                maxWidth="400px"
                height="300px"
                src={previewDetails?.lowResolution?.url}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setPreviewModal(false)}>Cancel</Button>
            </DialogActions>
          </Dialog>

          {/* <!--- Asset Listing ---> */}
          <Box
            sx={{
              width: '100%',
              // mt: '40px',
            }}
          >
            <MuiTable
              columns={columns}
              records={props?.MyAsset || []}
              showCheckbox={false}
              keyField={'id'}
              onTableChange={handleTableChange}
              onRowsSelect={selectedRows => {}}
              searchQuery=""
              remote={true}
              paginationProps={{
                totalCount: pageable?.totalElements,
                rowsPerPage: pageable?.pageSize,
                page: pageable?.pageNo,
                orderBy: pageable?.sortBy,
                order: pageable?.sortDir,
              }}
              showToolbar={true}
            />
          </Box>
        </Paper>
      </Grid>
    </>
  )
}
const mapStateToProps = state => ({
  MyAsset: state.myasset.myAssetList,
  pageable: state.myasset.pageable,
})

const mapActionsToProps = {
  MyAssetListAction,
  AllMyAssetListAction,
  MyAssetListDeleteAction,
}

export default connect(mapStateToProps, mapActionsToProps)(MyAssetList)
